import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

const Content = () => {
  return (
    <section id="content">
      <div className="container">
        <h2 className="title">
          Por que nos <span>contratar?</span>
        </h2>

        <p className="text">
          Contamos com uma equipe de profissionais capacitados e comprometidos
          em entregar sempre os melhores resultados aos nossos clientes, com
          rapidez, eficiência e excelência no acabamento.
        </p>

        <p>Faça seu orçamento conosco via Whatsapp:</p>

        <CTA />
      </div>
    </section>
  );
};

export default Content;

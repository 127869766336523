import React from "react";
import "./styles.css";

import logo from "../../assets/LOGO-TOPO.png";
import CTA from "../CTA";

const Menu = () => {
  return (
    <nav id="menu">
      <div className="container">
        <figure className="logo">
          <img src={logo} alt="Logo" />
        </figure>

        <CTA />
      </div>
    </nav>
  );
};

export default Menu;

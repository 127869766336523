import React from "react";
import "./styles.css";

import gallery from "../../assets/ICONES.png";
import galleryMobile from "../../assets/mobile/ICONES.png";

const Others = () => {
  return (
    <section id="others">
      <div className="container">
        <h2 className="title">TAMBÉM <span>PINTAMOS:</span></h2>

        <div className="gallery">
          <figure>
            <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? galleryMobile
                  : gallery
              } alt="Galeria de imagens" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Others;

import React from "react";
import "./styles.css";

import logo from "../../assets/LOGO-rodape.png";
import img from "../../assets/FORM_PAGAMENTO.png";
import imgMobile from "../../assets/mobile/FORM_PAGAMENTO.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <div className="left">
            <figure>
              <img src={logo} alt="Logo" />
            </figure>

            <p>
              <span>Endereço:</span> <br />
              Rua Esperança, Quadra 52 l 12 casa 1 - Bairro Jardim Nova
              Esperança / Goiânia-Go.
            </p>
          </div>

          <div className="right">
            <figure>
              <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? imgMobile
                  : img
                 } alt="Formas de Pagamento" />
            </figure>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">© 2022 | Pintor Jogo Rápido . Todos os direitos reservados.</p>

          <p>
            Desenvolvido por{" "}
            <a href="https://pluspage.com.br" target="_blank" rel="noreferrer">
              Pluspage.com.br
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./styles.css";

import cta from "../../assets/CALL_WHATSAPP.png";

const CTA = () => {
  return (
    <figure id="cta">
      <a
        href="http://wa.me/+5562999059301?text=Gostaria%20de%20saber%20mais"
        target="_blank"
        rel="noreferrer"
      >
        <img src={cta} alt="Chamada para ação de Whatsapp" />
      </a>
    </figure>
  );
};

export default CTA;

import React from "react";
import "./styles.css";

import pintura from "../../assets/PINTURA.png";
import pinturaMobile from "../../assets/mobile/PINTURA2.png";
import construcao from "../../assets/CONSTRUCAO.png";
import construcaoMobile from "../../assets/mobile/CONSTRUCAO2.png";
import eletrica from "../../assets/ELETRICA.png";
import eletricaMobile from "../../assets/mobile/ELETRICA2.png";
import hidraulica from "../../assets/HIDRAULICA.png";
import hidraulicaMobile from "../../assets/mobile/HIDRAULICA2.png";
import cta from "../../assets/CALL_WHATSAPP_CARD.png";

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <h2 className="title">
          NOSSOS <span>SERVIÇOS</span>
        </h2>

        <p className="text">
          Além do serviço de pintura, também contamos com pedreiros, encanadores
          e eletricistas!
        </p>

        <div className="gallery">
          <figure>
            <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? pinturaMobile
                  : pintura
              } alt="Pintura" className="jobs" />
            <a
              href="http://wa.me/+5562999059301?text=Gostaria%20de%20saber%20mais"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cta}
                alt="Chamada para ação de Orçamento"
                className="cta_card"
              />
            </a>
          </figure>

          <figure>
            <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? construcaoMobile
                  : construcao
              } alt="Construção e Reformas" className="jobs" />
            <a
              href="http://wa.me/+5562999059301?text=Gostaria%20de%20saber%20mais"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cta}
                alt="Chamada para ação de Orçamento"
                className="cta_card right"
              />
            </a>
          </figure>

          <figure>
            <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? eletricaMobile
                  : eletrica
              } alt="Elétrica" className="jobs" />
            <a
              href="http://wa.me/+5562999059301?text=Gostaria%20de%20saber%20mais"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cta}
                alt="Chamada para ação de Orçamento"
                className="cta_card left"
              />
            </a>
          </figure>

          <figure>
            <img src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? hidraulicaMobile
                  : hidraulica
              } alt="Hidráulica" className="jobs" />
            <a
              href="http://wa.me/+5562999059301?text=Gostaria%20de%20saber%20mais"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cta}
                alt="Chamada para ação de Orçamento"
                className="cta_card right" 
              />
            </a>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Services;

import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

import img from "../../assets/Pintor_1.png";

const Header = () => {
  return (
    <header id="header">
      <div className="header1">
        <div className="container">
          <div className="left">
            <h2 className="title">Está procurando um</h2>

            <figure>
              <img src={img} alt="Pintor" />
            </figure>

            <p className="text">
              Pintura Profissional, interna e externa em casas, apartamentos
              prédios e estruturas metálicas.
            </p>

            <p className="cta">
              Faça seu orçamento conosco via Whatsapp
              <CTA />
            </p>
          </div>
        </div>
      </div>

      <div className="header2">
        <p className="cta">
          Faça seu orçamento via Whatsapp
          <CTA />
        </p>
      </div>
    </header>
  );
};

export default Header;

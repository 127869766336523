import React from "react";
import "./styles.css";

import CTA from "../../components/CTA";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2 className="title">
          Está precisando <span>renovar a pintura</span> do seu apartamento?
        </h2>

        <p className="text">
          Somos especialistas em pintura de apartamentos, casas e condomínios. A
          qualidade e rapidez na entrega do serviço é o que nos faz conquistar a
          satisfação dos nossos clientes.
        </p>

        <p>Faça seu orçamento via Whatsapp:</p>
        <CTA />
      </div>
    </section>
  );
};

export default About;
